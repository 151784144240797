<script setup>
import { ref } from 'vue';

const props = defineProps({ 
    message: String,
    title: String,
    promise: Promise
});

let resolved = ref(false);
let rejected = ref(false);

props.promise.then(() => {
    resolved.value = true;
}, () => {
    rejected.value = true;
})

</script>

<template>
    <div class="toast-header">
        <strong class="me-auto">{{ props.title }}</strong>
        <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body d-flex">
        <i class="bi bi-circle-fill me-2" :class="resolved == true ? 'text-success' : (rejected == true ? 'text-danger' : 'text-warning')"
></i> {{ props.message }} <div v-if="!resolved && !rejected" class="spinner-border spinner-border-sm ms-auto" aria-hidden="true"></div>
    </div>
</template>